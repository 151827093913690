.message {
  position: absolute;
  left: 15rem;
  right: 15rem;
  bottom: 0;
  height: 20rem;
  border: 1px solid black;
  background: rgba(0, 0, 0, 0.25);
  font-size: large;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  border-radius: 5px;
  z-index: 10;
  padding: 1rem;
}

.message button {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  font-family: 'Courier New', Courier, monospace;
  color: white;
  background-color: transparent;
  border: 3px solid black;
  font-size: 1.5rem;
}

.guess {
  margin: 4rem auto;
  text-align: center;
  font-size: 3rem;
}

.letter {
  display: inline-block;
  margin-right: 0.25rem;
  color: white;
}

.hint {
  font-size: 1rem;
  font-style: italic;
}