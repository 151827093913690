html,
body,
#root {
  min-height: 1px;
  height: 100%;
  padding: 0;
  margin: 0;
}
button {
  padding: 0.5rem;
  color: red;
  margin: 0.5rem;
}
.stats {
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  text-align: center;
  position: absolute;
  width: 100%;
  font-family: "Yuji Boku", serif;
}
.fail {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
